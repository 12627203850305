/* You can add global styles to this file, and also import other style files */
@import '@siemens/ix-icons/dist/css/ix-icons.css';
@import '@siemens/ix/dist/siemens-ix/siemens-ix.css';
@import '@siemens/ix-brand-theme/dist/ix-brand-theme/ix-brand-theme.css';

/* You can add global styles to this file, and also import other style files */
:root {
  --space-xs: 3px;
  --space-md: 18px;
  --space-lg: 28px;
  --space-xl: 74px;
  --space-32px: 32px;
  --space-26px:26px;
  --space-27px:27px;
  --space-56px: 3.5rem;
  --space-19px: 19px;
  --space-22px: 22px;
  --space-12px: 12px;
  --space-48px: 48px;
  --space-11px: 11px;
  --space-80px: 80px;
  --space-90px: 90px;
  --space-10px: 10px;
  --space-14px: 14px;
  --space-8px: 8px;

}

.add-quicklinks-backdrop {
  .modal-dialog {
    width: 480px;
    max-width: 480px;
  }
}

.cdt-backdrop {
  .modal-dialog {
    width: 960px;
    max-width: 960px;
  }
}

.token{
  display: none;
}








